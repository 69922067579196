.service-icon {
    height: 100px;
    filter: invert(27%) sepia(30%) saturate(5917%) hue-rotate(337deg) brightness(92%) contrast(86%);
    display: block;
    margin: 0 auto;
}

.landing-tattoo-image {
    filter: invert(27%) sepia(30%) saturate(5917%) hue-rotate(337deg) brightness(92%) contrast(86%);;
    position: absolute;
    left: -30%;
    top: 20%;
    height: 350px;
    width: 350px;
}

.image-landing-container {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.left-landing-side {
    text-align: right;
    float: right;
}

.logo-image {
    max-width: 20rem;
}

.logo-slider {
    max-width: 70%;
}

@media screen and (max-width: 912px) {
    .left-landing-side {
        float: none;
        text-align: center;
    }
}


